import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Podcast, Episode } from '../types/index';
import { getPodcast, getEpisodes } from '../services/api';
import { EpisodeModal } from '../components/episode/EpisodeModal';

const hasGuestName = (episode: Episode): episode is Episode & { guest_name: string } => {
  return typeof episode.guest_name === 'string';
};

export const PodcastPage = () => {
  const { id } = useParams<{ id: string }>();
  const [podcast, setPodcast] = useState<Podcast | null>(null);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedEpisode, setSelectedEpisode] = useState<Episode | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [podcastData, episodesData] = await Promise.all([
          getPodcast(id!),
          getEpisodes(id!)
        ]);
        setPodcast(podcastData);
        setEpisodes(episodesData);
      } catch (error) {
        console.error('Failed to fetch podcast data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
      </div>
    );
  }

  if (!podcast) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <h1 className="text-2xl text-white">Podcast not found</h1>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white/20 backdrop-blur-sm rounded-lg shadow-md p-8 mb-8">
        <h1 className="text-3xl font-bold text-white mb-4">{podcast.title}</h1>
        <p className="text-gray-200 mb-4">{podcast.description}</p>
        <div className="text-gray-300">
          <span className="mr-4">Channel: {podcast.channel_name}</span>
          <a
            href={podcast.channel_url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 hover:text-blue-300"
          >
            Visit Channel
          </a>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {episodes.map((episode) => (
          <div
            key={episode.id}
            className="bg-white/20 backdrop-blur-sm rounded-lg p-6 cursor-pointer hover:bg-white/30 transition-all"
            onClick={() => setSelectedEpisode(episode)}
          >
            {episode.thumbnail_url && (
              <img
                src={episode.thumbnail_url}
                alt={episode.title}
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
            )}
            <h3 className="text-xl font-semibold text-white mb-2">
              {episode.title}
            </h3>
            {hasGuestName(episode) && (
              <p className="text-gray-200 mb-2">
                Guest: {episode.guest_name}
              </p>
            )}
            <p className="text-gray-300 line-clamp-2">{episode.description}</p>
          </div>
        ))}
      </div>

      {selectedEpisode && (
        <EpisodeModal
          episode={selectedEpisode}
          onClose={() => setSelectedEpisode(null)}
        />
      )}
    </div>
  );
}; 