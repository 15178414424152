type LogLevel = 'info' | 'warn' | 'error' | 'debug';

interface LoggerOptions {
  level?: LogLevel;
  data?: unknown;
}

class Logger {
  private static isDevelopment = process.env.NODE_ENV !== 'production';

  static log(message: string, options: LoggerOptions = {}) {
    const { level = 'info', data } = options;
    const timestamp = new Date().toISOString();
    const logMessage = `[${timestamp}] ${level.toUpperCase()}: ${message}`;

    // Always log to console in development
    if (this.isDevelopment) {
      switch (level) {
        case 'info':
          console.log(logMessage, data);
          break;
        case 'warn':
          console.warn(logMessage, data);
          break;
        case 'error':
          console.error(logMessage, data);
          break;
        case 'debug':
          console.debug(logMessage, data);
          break;
      }
    }

    // In production, you might want to send to a logging service
    // Add your production logging logic here
  }

  static debug(message: string, data?: unknown) {
    this.log(message, { level: 'debug', data });
  }

  static info(message: string, data?: unknown) {
    this.log(message, { level: 'info', data });
  }

  static warn(message: string, data?: unknown) {
    this.log(message, { level: 'warn', data });
  }

  static error(message: string, data?: unknown) {
    this.log(message, { level: 'error', data });
  }
}

export default Logger; 