import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { EnhancedIdea } from '../types';
import { IdeaCard } from '../components/idea/IdeaCard';
import { getAllIdeas } from '../services/api';
import { Button } from '../components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';
import { Input } from '../components/ui/input';
import { useDebounce } from '../hooks/useDebounce';

interface ExploreIdeasPageProps {
  isAuthenticated: boolean;
}

export const ExploreIdeasPage = ({ isAuthenticated }: ExploreIdeasPageProps) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [ideas, setIdeas] = useState<EnhancedIdea[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const debouncedKeyword = useDebounce(keyword, 500);
  const [showLoginMessage, setShowLoginMessage] = useState(false);

  const page = Number(searchParams.get('page')) || 1;
  const sortBy = searchParams.get('sortBy') || 'upvotes';
  const sortOrder = searchParams.get('sortOrder') || 'desc';
  const businessArea = searchParams.get('businessArea') || '';

  useEffect(() => {
    const fetchIdeas = async () => {
      try {
        setLoading(true);
        const response = await getAllIdeas({
          page,
          sortBy,
          sortOrder,
          businessArea,
          keyword: debouncedKeyword,
        });
        setIdeas(response.items);
        setTotalPages(response.total_pages);
      } catch (error) {
        console.error('Failed to fetch ideas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIdeas();
  }, [page, sortBy, sortOrder, businessArea, debouncedKeyword]);

  useEffect(() => {
    setShowLoginMessage(!isAuthenticated && page > 2);
  }, [page, isAuthenticated]);

  const updateSearchParams = (updates: Record<string, string>) => {
    const newParams = new URLSearchParams(searchParams);
    Object.entries(updates).forEach(([key, value]) => {
      if (value) {
        newParams.set(key, value);
      } else {
        newParams.delete(key);
      }
    });
    setSearchParams(newParams);
  };

  const handlePageChange = (newPage: number) => {
    if (!isAuthenticated && newPage > 2) {
      setShowLoginMessage(true);
      return;
    }
    updateSearchParams({ page: String(newPage) });
  };

  const handleAuthClick = (path: 'login' | 'signup') => {
    // First, store the current page state in sessionStorage
    sessionStorage.setItem('returnToPath', window.location.pathname + window.location.search);
    
    // Add a fade-out effect before navigation
    const overlay = document.querySelector('.auth-overlay');
    if (overlay) {
      overlay.classList.add('fade-out');
      
      // Navigate after animation completes
      setTimeout(() => {
        navigate(`/${path}`);
      }, 300);
    }
  };

  const handleSortChange = (type: string, value: string) => {
    if (!isAuthenticated) {
      setShowLoginMessage(true);
      return;
    }
    updateSearchParams({ [type]: value, page: '1' });
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-900 via-blue-900 to-purple-900">
      <div className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold text-white mb-12 text-center">Explore Ideas</h1>

        {/* Search and Sort Controls */}
        <div className="max-w-4xl mx-auto mb-8">
          <div className="flex flex-col md:flex-row gap-4 items-center">
            {/* Search Bar */}
            <div className="w-full md:flex-1">
              <Input
                placeholder="Search ideas..."
                value={keyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value)}
                className="w-full bg-white/10 border-white/20 text-white placeholder:text-white/50"
              />
            </div>

            {/* Sort Controls */}
            <div className="flex gap-2 w-full md:w-auto">
              <Select
                value={sortBy}
                onValueChange={(value: string) => handleSortChange('sortBy', value)}
              >
                <SelectTrigger className="w-[140px] bg-white/10 border-white/20 text-white">
                  <SelectValue placeholder="Sort by" />
                </SelectTrigger>
                <SelectContent className="bg-white/90 backdrop-blur-lg">
                  <SelectItem value="upvotes">Upvotes</SelectItem>
                  <SelectItem value="market_size">Market Size</SelectItem>
                  <SelectItem value="business_area">Business Area</SelectItem>
                </SelectContent>
              </Select>

              <Select
                value={sortOrder}
                onValueChange={(value: string) => handleSortChange('sortOrder', value)}
              >
                <SelectTrigger className="w-[140px] bg-white/10 border-white/20 text-white">
                  <SelectValue placeholder="Sort order" />
                </SelectTrigger>
                <SelectContent className="bg-white/90 backdrop-blur-lg">
                  <SelectItem value="desc">Descending</SelectItem>
                  <SelectItem value="asc">Ascending</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>

        {/* Ideas Grid */}
        {loading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
          </div>
        ) : (
          <>
            <div className={`grid gap-6 max-w-4xl mx-auto ${showLoginMessage ? 'content-blur' : ''}`}>
              {ideas.map((idea) => (
                <IdeaCard key={idea.id} idea={idea} />
              ))}
            </div>
            
            {/* Login Message Overlay */}
            {showLoginMessage && (
              <div className="auth-overlay animate-fade-in">
                <div className="flex flex-col items-center justify-center h-full text-center p-8">
                  <div className="space-y-6 max-w-lg">
                    <h3 className="text-4xl font-bold text-white">
                      Please sign in to view more ideas..
                    </h3>
                    <p className="text-white/90 text-lg">
                      Create a free account or sign in to continue reading innovative startup ideas from our community.
                    </p>
                    <div className="flex flex-col gap-4 mt-8">
                      <Button
                        onClick={() => handleAuthClick('signup')}
                        className="w-full bg-white hover:bg-white/90 text-purple-900 py-3 text-lg font-semibold transition-all transform hover:scale-105"
                      >
                        Sign Up
                      </Button>
                      <Button
                        variant="outline"
                        onClick={() => handleAuthClick('login')}
                        className="w-full border-2 border-white text-white hover:bg-white/10 py-3 text-lg font-semibold transition-all transform hover:scale-105"
                      >
                        Log in
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Pagination Controls */}
            <div className="flex justify-center items-center gap-2 mt-8">
              <Button
                variant="secondary"
                disabled={page === 1}
                onClick={() => handlePageChange(page - 1)}
                className="bg-white/10 hover:bg-white/20 text-white border-white/20"
              >
                Previous
              </Button>
              
              <span className="flex items-center px-4 text-white">
                Page {page} of {totalPages}
              </span>

              <Button
                variant="secondary"
                disabled={page === totalPages}
                onClick={() => handlePageChange(page + 1)}
                className="bg-white/10 hover:bg-white/20 text-white border-white/20"
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
      
      {/* Decorative Elements */}
      <div className="fixed top-40 left-10 w-20 h-20 bg-pink-500 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed top-60 right-10 w-32 h-32 bg-purple-500 rounded-full blur-3xl opacity-20"></div>
      <div className="fixed bottom-40 left-1/4 w-24 h-24 bg-blue-500 rounded-full blur-3xl opacity-20"></div>
    </div>
  );
};

// Remove the unused styles constant below 