import { useState } from 'react';
import { Idea } from '../../types';
import { updateIdeaVotes } from '../../services/api';

interface IdeaCardProps {
  idea: Idea;
}

export const IdeaCard = ({ idea: initialIdea }: IdeaCardProps) => {
  const [idea, setIdea] = useState(initialIdea);
  const [isVoting, setIsVoting] = useState(false);

  const handleVote = async (type: 'upvote' | 'downvote') => {
    if (isVoting) return;
    
    setIsVoting(true);
    try {
      const updatedIdea = await updateIdeaVotes(idea.id, type);
      setIdea(updatedIdea);
    } catch (error) {
      console.error('Failed to update vote:', error);
    } finally {
      setIsVoting(false);
    }
  };

  return (
    <div className="bg-white/20 backdrop-blur-sm rounded-lg p-6 hover:bg-white/30 transition-all">
      <h3 className="text-xl font-semibold text-white mb-2">{idea.title}</h3>
      <p className="text-gray-200 mb-4">{idea.short_description}</p>
      
      <div className="flex items-center justify-between mb-4">
        <div className="text-sm text-gray-300">
          <span className="font-medium">Market Size:</span> ${idea.market_size}M
        </div>
        <div className="text-sm text-gray-300">
          <span className="font-medium">Industry:</span> {idea.business_area}
        </div>
      </div>

      <div className="text-gray-200 mb-6">
        {idea.detailed_description}
      </div>

      <div className="flex items-center justify-between border-t border-white/10 pt-4">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => handleVote('upvote')}
            disabled={isVoting}
            className={`flex items-center space-x-1 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'upvote' ? 'text-green-400' : 'text-gray-300 hover:text-green-400'
            } transition-colors`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span>{idea.upvotes}</span>
          </button>

          <button
            onClick={() => handleVote('downvote')}
            disabled={isVoting}
            className={`flex items-center space-x-1 ${
              isVoting ? 'opacity-50 cursor-not-allowed' : 
              idea.user_vote === 'downvote' ? 'text-red-400' : 'text-gray-300 hover:text-red-400'
            } transition-colors`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M16.707 10.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l4.293-4.293a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            <span>{idea.downvotes}</span>
          </button>
        </div>

        <div className="text-sm text-gray-300">
          {new Date(idea.created_at).toLocaleDateString()}
        </div>
      </div>
    </div>
  );
}; 