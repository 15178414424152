import { useState, useEffect } from 'react';
import { Episode, Idea } from '../../types/index';
import { getIdeas } from '../../services/api';
import { IdeaCard } from '../idea/IdeaCard';

interface EpisodeModalProps {
  episode: Episode;
  onClose: () => void;
}

export const EpisodeModal = ({ episode, onClose }: EpisodeModalProps) => {
  const [ideas, setIdeas] = useState<Idea[]>([]);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger enter animation
    requestAnimationFrame(() => {
      setIsVisible(true);
    });

    const fetchIdeas = async () => {
      try {
        const fetchedIdeas = await getIdeas(episode.id);
        setIdeas(fetchedIdeas);
      } catch (error) {
        console.error('Failed to fetch ideas:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchIdeas();
  }, [episode.id]);

  const handleClose = () => {
    setIsVisible(false);
    // Wait for exit animation to complete
    setTimeout(onClose, 300);
  };

  return (
    <div 
      className={`fixed inset-0 bg-gradient-to-br from-purple-600/95 to-blue-600/95 backdrop-blur-sm z-50 transition-transform duration-300 ease-in-out ${
        isVisible ? 'translate-y-0' : 'translate-y-full'
      }`}
    >
      <div className="h-screen overflow-y-auto">
        {/* Header */}
        <div className="sticky top-0 bg-white/10 backdrop-blur-sm border-b border-white/10 z-10">
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <button
              onClick={handleClose}
              className="text-white/80 hover:text-white flex items-center gap-2 transition-colors"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Back
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="container mx-auto px-4 py-6">
          {/* Episode Section */}
          <div className="max-w-4xl mx-auto mb-12">
            <h1 className="text-4xl font-bold text-white mb-6">{episode.title}</h1>
            
            <div className="aspect-video mb-8">
              <iframe
                src={episode.youtube_url.replace('watch?v=', 'embed/')}
                title={episode.title}
                className="w-full h-full rounded-lg shadow-lg"
                allowFullScreen
              />
            </div>

            {episode.guest_name && (
              <p className="text-xl text-white/90 mb-4">
                Guest: {episode.guest_name}
              </p>
            )}

            <p className="text-gray-200 mb-8 text-lg">{episode.description}</p>

            {episode.summary && (
              <div className="bg-white/10 backdrop-blur-sm p-6 rounded-lg mb-12">
                <h3 className="font-semibold text-xl text-white mb-3">Summary</h3>
                <p className="text-gray-200">{episode.summary}</p>
              </div>
            )}
          </div>

          {/* Ideas Section */}
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold mb-8 text-white">Business Ideas</h2>
            
            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-white"></div>
              </div>
            ) : ideas.length > 0 ? (
              <div className="grid gap-6">
                {ideas.map((idea) => (
                  <IdeaCard key={idea.id} idea={idea} />
                ))}
              </div>
            ) : (
              <div className="bg-white/10 backdrop-blur-sm rounded-lg p-12 text-center">
                <p className="text-white/80 text-lg">
                  No business ideas found for this episode.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}; 